<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <v-btn
          color="primary"
          @click="isAddingAddr = true"
        >
          <v-icon>{{ mdiPlus }}</v-icon>
          Adresse hinzufügen
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              color="primary"
              icon
              small
              outlined
              @click.stop="infoDialogForAddress = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>{{ mdiHelp }}</v-icon>
            </v-btn>
          </template>
          Aufschlüsselung von Adresstypen
        </v-tooltip>
      </v-col>
    </v-row>
    <v-card
      v-if="isAddingAddr"
      class="addresscard"
      elevation="0"
      outlined
    >
      <v-card-text>
        <org-details-address-common
          :organization="organization"
          :org-addr="addOrgAddr"
          @dataChanged="handleDataChanged"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          @click="addAddress"
        >Speichern
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="isAddingAddr = false"
        >Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row justify="center">
      <v-dialog
        v-model="infoDialogForAddress"
        scrollable
        transition="dialog-bottom-transition"
        width="800"
      >
        <v-card>
          <v-card-title>
            <h5>Aufschlüsselung von Adresstypen</h5>
          </v-card-title>
          <v-card-text>
            <p><strong>Hauptadresse</strong></p>
            <p>
              Addresstyp wird in Mails im Namen der Organisation (z. B. Dankesmail), für Kollektenweiterleitungen und
              als erste Option für Spendenbescheinigungen verwendet.
            </p>

            <p><strong> Rechnungsadresse </strong></p>
            <p>Addresstyp wird für Rechnungen verwendet. Wenn einzige Adresse: Nutzung für Spendenbescheinigungen,
              Mails. </p>

            <p><strong> Standort </strong></p>
            <p> Addresstyp wird für Geokoordinaten für kollekte.app / spende.app verwendet.</p>

            <p><strong> Sonstige </strong></p>
            <p> Addresstyp wird nur verwendet, wenn andere Typen leer. </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="infoDialogForAddress = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mdiPencil, mdiPlus, mdiHelp } from "@mdi/js"
import { mapGetters } from "vuex"
import { WRITE_ORG_DATA } from '@/lib/permission-tools'
import { ADD_ORGANIZATION_ADDRESS } from "@/store/action-types"
import moment from "moment"
import OrgDetailsAddressCommon from "@/components/OrgDetailsAddressCommon"

export default {
  name: "OrganizationDetailsAddressAdd",
  components: {
    OrgDetailsAddressCommon
  },
  props: {
    organization: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      isAddingAddr: false,
      addOrgAddr: {
        region: 'Deutschland'
      },
      mdiPencil,
      mdiPlus,
      mdiHelp,
      addressTypes: {
        primary: 'Hauptadresse',
        billing: 'Rechnungsadresse',
        physical: 'Standort',
        other: 'Sonstige'
      },
      infoDialogForAddress: false,
      addressesOrder: ['primary', 'physical', 'billing', 'other']
    }
  },
  computed: {
    ...mapGetters('user', ['isSuperuser', 'hasPermission']),
    hasEditingPermissions () {
      return this.hasPermission(this.organization.url, WRITE_ORG_DATA)
    }
  },
  methods: {
    async addAddress () {
      const currentTime = moment().format()
      const addressPayload = {
        ...this.addOrgAddr,
        valid_from: currentTime,
        organization: this.organization.url
      }
      const addOrgAddress = await this.$store.dispatch('organization/' + ADD_ORGANIZATION_ADDRESS, addressPayload)
      if (addOrgAddress) {
        this.isAddingAddr = false
        this.addOrgAddr = {
          region: 'Deutschland'
        }
      }
    },
    handleDataChanged (newValue) {
      this.isAddingAddr = newValue
    }
  }
}
</script>

<style
  lang="stylus"
  scoped
>
.addresscard
  padding-bottom: 20px

  .title
    text-overflow: unset
    white-space: unset

  .content
    text-overflow: unset
    white-space: unset

</style>
