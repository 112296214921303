import {
  ADD_USER,
  UPDATE_CURRENT_USER,
  FETCH_USER_DATA,
  FETCH_USER_LIST,
  LOGIN,
  IMPERSONATE,
  RESTORE_AUTH,
  FETCH_ORGANIZATIONS, AUTHORIZE_OAUTH, FETCH_CREDENTIAL_LIST, FINISH_LOGIN, UPDATE_OTHER_USER
} from './action-types'
import {
  CLEAR_CREDENTIALS,
  CLEAR_DATA,
  SET_AUTHENTICATION_DATA, SET_CREDENTIALS, SET_OAUTH_STATE,
  SET_USER_DATA,
  SET_USER_LIST
} from './mutation-types'
import router from '../router'
import { cachedActionWrapper, loadingStateWrapper } from '@/modules/common/store/tools'
import { CACHE_USER_LIST, CACHE_USER_DATA, CACHE_USER_CREDENTIALS } from '@/store/cache-types'
import {
  MINOR_RIGHTS,
  PERM_TYPE_INTERACT_COLLECTION_PLAN,
  PERM_TYPE_INTERACT_GENERAL, PERM_TYPE_INTERACT_WRITE_ANY, PERM_TYPE_READ_OTHER, READ, READ_CPLAN,
  READ_NONSENSITIVE, WRITE, WRITE_CPLAN
} from '@/lib/permission-tools'
import { SET_ERROR_MESSAGE } from "@/modules/common/store/mutation-types"
import Vue from 'vue'

export default {
  namespaced: true,
  state: function () {
    return {
      user: null,
      auth: {
        token: null,
        origToken: null,
        error: null
      },
      userList: [],
      oauth: false,
      credentialsByUrl: {},
    }
  },
  getters: {
    userAuthToken: (state) => {
      return state?.auth?.token ?? null
    },
    allUsers: (state) => {
      const result = {}
      if (!state.userList) {
        return {}
      }
      state.userList.forEach((val) => {
        result[val.url] = val
      })
      return result
    },
    isLoggedIn: (state) => {
      return !!state?.auth?.token
    },
    isImpersonated: (state) => {
      return !!(state?.auth?.origToken ?? null)
    },
    isSuperuser: (state) => {
      // eslint-disable-next-line camelcase
      return state?.user?.is_superuser
    },
    credentials: (state) => {
      return Object.values(state.credentialsByUrl)
    },
    ownCredentials: (state, getters) => {
      return (getters?.credentials ?? []).filter(
        credential => credential?.user && credential?.user === state?.user?.url
      )
    },
    hasMultipleOrgs: (state, getters) => {
      // return true if more than one organization has more than minor rights
      return Object.values(getters.effectivePermissionsByUrl).filter(
        rights => Array.from(rights).some(right => !MINOR_RIGHTS.has(right))
      ).length > 1
    },
    hasRole: (state) => {
      return (organizationUrl, role) => {
        return state?.user?.permissions?.some(permission => permission.organization === organizationUrl && permission.role === role)
      }
    },
    hasPermission: (state, getters, rootState) => {
      function anyPermissionPart (permission) {
        return [...(rootState.staticConfiguration?.permissionParts[permission] ?? [permission])]
      }

      return (organizationUrl, permType = PERM_TYPE_INTERACT_GENERAL) => {
        if (getters.isSuperuser) {
          return true
        }

        let allowedPermissions = []
        let exceptedPermissions = []

        if (permType === PERM_TYPE_INTERACT_GENERAL) {
          allowedPermissions = null
          exceptedPermissions = [...MINOR_RIGHTS]
        } else if (permType === PERM_TYPE_INTERACT_COLLECTION_PLAN) {
          allowedPermissions = [...anyPermissionPart(READ_CPLAN), ...anyPermissionPart(WRITE_CPLAN)]
        } else if (permType === PERM_TYPE_INTERACT_WRITE_ANY) {
          allowedPermissions = [...anyPermissionPart(WRITE)]
          exceptedPermissions = [...anyPermissionPart(WRITE_CPLAN)]
        } else if (permType === PERM_TYPE_READ_OTHER) {
          allowedPermissions = [...anyPermissionPart(READ)]
          exceptedPermissions = [...anyPermissionPart(READ_CPLAN), READ_NONSENSITIVE]
        } else {
          allowedPermissions = [permType]
        }

        const perms = [...(getters.effectivePermissionsByUrl[organizationUrl] ?? new Set())].filter(
          item => (allowedPermissions === null || allowedPermissions.includes(item)) && (
            !exceptedPermissions.includes(item)
          )
        )

        return perms.length > 0
      }
    },
    effectivePermissionsByUrl: (state, getters, rootState, rootGetters) => {
      const pmap = rootState.staticConfiguration.permissionsMap ?? {}
      const allOrgs = rootGetters['organization/allOrganizations'] ?? {}

      // Short circuit: Superuser gets all permissions everywhere
      // eslint-disable-next-line camelcase
      if (getters.isSuperuser) {
        const retval = {}
        for (const org of Object.keys(allOrgs)) {
          retval[org] = rootState.staticConfiguration.permissionParts?.EVERYTHING ?? new Set()
        }
        return retval
      }

      const orgTree = rootGetters['organization/orgtree'] ?? {}
      // Algorithm description.
      // This is a parallel tree walk. We'll keep three mappings from organization url to permission set:
      //    movingUp, results, movingDown
      // We'll also keep a set of organization urls called touched
      // Initialize the algorithm from the permissions array, so for each organization mentioned in the array
      //  assign movingUp / results / movingDown = permissionsMap[role].ancestors / own / descendants
      //  assign touched = set of organization urls mentioned in permissions array
      // In the iteration step we'll go through the "touched" set and create a new touched set as follows:
      //  propagate the current organization movingUp set to its parent (merge into movingUp and results)
      //  propagate the current organization movingDown set into all its children (merge into movingDown and results)
      //  the new touched set will be the set of organizations whose movingUp or movingDown sets have changed
      //  iterate until touched is empty
      // Complexity of the algorithm is in
      //    O(number of permissions x height of organization tree x width of organization tree)

      const movingUp = {}
      const results = {}
      const movingDown = {}
      let touched = new Set()
      // Pre-initialize arrays with empty sets, so that we don't have to worry about undefined entries below
      for (const org of Object.keys(allOrgs)) {
        movingUp[org] = new Set()
        movingDown[org] = new Set()
        results[org] = new Set()
      }

      // Initialize algorithm
      for (const { organization, role } of (state?.user?.permissions ?? [])) {
        const permissions = pmap[role] ?? {}
        touched.add(organization)
        movingUp[organization] = new Set([...(movingUp[organization] ?? []), ...(permissions?.ancestors ?? [])])
        movingDown[organization] = new Set([...(movingDown[organization] ?? []), ...(permissions?.descendants ?? [])])
        results[organization] = new Set([...(results[organization] ?? []), ...(permissions?.own ?? [])])
      }

      // Iterate
      while (touched.size) {
        const oldTouched = touched
        touched = new Set()

        for (const org of oldTouched) {
          // Propagate up
          if (movingUp[org].size) {
            const parent = allOrgs[org]?.parent
            if (parent) {
              let changed = false
              for (const permission of movingUp[org]) {
                if (!movingUp[parent].has(permission)) {
                  movingUp[parent].add(permission)
                  results[parent].add(permission)
                  changed = true
                }
              }
              if (changed) {
                touched.add(parent)
              }
            }
          }

          // Propagate down
          if (movingDown[org].size) {
            for (const child of orgTree[org] ?? []) {
              let changed = false
              for (const permission of movingDown[org]) {
                if (!movingDown[child].has(permission)) {
                  movingDown[child].add(permission)
                  results[child].add(permission)
                  changed = true
                }
              }
              if (changed) {
                touched.add(child)
              }
            }
          }
        }
      }

      // Remove empty items
      return Object.entries(results).reduce((acc, [key, item]) => {
        if (item.size) {
          acc[key] = item
        }
        return acc
      }, {})
    }
  },
  mutations: {
    [SET_USER_DATA] (state, data) {
      if (!data) {
        data = {}
      }
      state.user = data
    },
    [SET_USER_LIST] (state, data) {
      state.userList = data || []
    },
    [SET_CREDENTIALS] (state, data) {
      for (const credential of data) {
        if (credential._delete) {
          // This is a hack
          Vue.delete(state.credentialsByUrl, credential.url)
        } else {
          Vue.set(state.credentialsByUrl, credential.url, credential)
        }
      }
    },
    [CLEAR_CREDENTIALS] (state) {
      state.credentials = {}
    },
    [SET_AUTHENTICATION_DATA] (state, { data, error, impersonate } = { data: null, error: null, impersonate: false }) {
      state.auth = {
        // eslint-disable-next-line camelcase
        token: data?.auth_token ?? null,
        origToken: impersonate ? (state?.auth?.token ?? null) : null,
        error
      }
    },
    [SET_OAUTH_STATE] (state, data) {
      state.oauth = data
    }
  },
  actions: {
    [FINISH_LOGIN]: async (context, { data, error } = { error: null }) => {
      await context.commit(SET_AUTHENTICATION_DATA, { data, error })
      if (context.state.oauth) {
        await context.dispatch(AUTHORIZE_OAUTH)
      } else if (router.currentRoute.name !== 'home') {
        await router.push({ name: 'home' })
      }

      return true
    },
    [LOGIN]: async (context, { username, password, otp }) => {
      return loadingStateWrapper(context, async () => {
        await context.commit(SET_AUTHENTICATION_DATA, { data: null, error: null })
        await context.dispatch(CLEAR_DATA, null, { root: true })

        const response = await context.rootGetters.restApi.post('user/obtain_token/', {
          username, password, otp
        })

        return await context.dispatch(FINISH_LOGIN, { data: response.data })
      }, async (error) => {
        await context.commit(SET_AUTHENTICATION_DATA, { data: null, error: String(error) })
        return false
      })
    },
    [IMPERSONATE]: async (context, { userUrl }) => {
      return loadingStateWrapper(context, async () => {
        try {
          await context.dispatch(CLEAR_DATA, { logout: false }, { root: true })
        } catch (e) {
          // console.exception("Error during clearing: ", e)
        }

        const response = await context.rootGetters.restApi.post(userUrl + 'impersonate/')

        await context.commit(SET_AUTHENTICATION_DATA, { data: response.data, error: null, impersonate: true })
        await Promise.allSettled([
          context.dispatch(FETCH_USER_DATA),
          context.dispatch('organization/' + FETCH_ORGANIZATIONS, {}, { root: true })
        ])
        if (router.currentRoute.name !== 'home') {
          await router.push({ name: 'home' })
        }

        return true
      }, async (error) => {
        await context.commit(SET_AUTHENTICATION_DATA, { data: null, error: String(error) })
        return false
      })
    },
    [AUTHORIZE_OAUTH]: async (context) => {
      const { state, commit, rootGetters } = context
      const params = new URLSearchParams()
      for (const [name, value] of Object.entries(state.oauth)) {
        params.set(name, value)
      }
      params.set('allow', 'true')
      try {
        await loadingStateWrapper(context, async () => {
          const response = await rootGetters.restApi.post(`user/authorize/`, params)
          if (response?.data?.redirect) {
            window.location.href = response.data.redirect
          }
        })
      } finally {
        await commit(SET_OAUTH_STATE, false)
      }
    },
    [RESTORE_AUTH]: async (context) => {
      return loadingStateWrapper(context, async () => {
        await context.dispatch(CLEAR_DATA, { logout: false }, { root: true })

        try {
          await context.rootGetters.restApi.post('user/logout/')
        } catch (e) {
          // console.exception("Error during logout: ", e)
        }

        await context.commit(SET_AUTHENTICATION_DATA, { data: { auth_token: context.state.auth.origToken }, error: null, impersonate: false })
        await Promise.allSettled([
          context.dispatch(FETCH_USER_DATA),
          context.dispatch('organization/' + FETCH_ORGANIZATIONS, {}, { root: true })
        ])
        if (context.rootState.route.name !== 'admin.users') {
          await router.push({ name: 'admin.users' })
        }
        return true
      }, async () => {
        return false
      })
    },
    [ADD_USER]: async (context, data) => {
      return loadingStateWrapper(context, async () => {
        await context.rootGetters.restApi.post('user/', data)
        return true
      }, async (error) => {
        const response = error.response
        if (![200, 500].includes(response.status)) {
          const errorMsg = JSON.stringify(response.data)
          await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${errorMsg}` }, { root: true })
          return false
        } else {
          await context.commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
          return false
        }
      })
    },
    [UPDATE_CURRENT_USER]: async (context, data) => {
      return loadingStateWrapper(context, async () => {
        const userUrl = data.url
        delete data.url
        if (data.password !== undefined && !data.password) {
          delete data.password
        }
        const response = await context.rootGetters.restApi.patch(userUrl, data)
        await context.commit(SET_USER_DATA, response.data)
        return true
      }, async () => {
        // FIXME Error handling
        return false
      })
    },
    [UPDATE_OTHER_USER]: async (context, data) => {
      return loadingStateWrapper(context, async () => {
        const userUrl = data.url
        delete data.url
        if (data.password !== undefined && !data.password) {
          delete data.password
        }
        return await context.rootGetters.restApi.patch(userUrl, data)
      }, async () => {
        // FIXME Error handling
        return false
      })
    },
    [FETCH_USER_DATA]: async (context) => {
      async function silentLogoutAndReturnToLogin () {
        // swallow error, log out user
        await context.dispatch(CLEAR_DATA, { logout: false }, { root: true })
        if (context.rootState.route.name !== 'login') {
          await router.push({ name: 'login' })
        }
      }
      return cachedActionWrapper(context, CACHE_USER_DATA, async () => {
        let response = null
        try {
          response = await context.rootGetters.restApi.get('user/me/')
        } catch (error) {
          const er = error.response
          /* Hack from https://stackoverflow.com/questions/53488315/what-is-fetchs-redirect-and-authorization-headers-expected-behavior-safari-ha
           *  adapted for axios */
          if (er.status === 401 && !er.request.responseURL.endsWith(er.config.url)) {
            /* Retry the request to the new URL */
            response = await context.rootGetters.restApi.get(er.request.responseURL)
          } else if (er.status === 401) {
            response = null
            await silentLogoutAndReturnToLogin()
          } else {
            /* Rethrow error */
            throw (error)
          }
        }

        if (response !== null) {
          await context.commit(SET_USER_DATA, response.data)
        }
      }, async () => {
        await silentLogoutAndReturnToLogin()
      })
    },
    [FETCH_USER_LIST]: async (context) => {
      return cachedActionWrapper(context, CACHE_USER_LIST, async () => {
        const response = await context.rootGetters.restApi.get('user/')
        await context.commit(SET_USER_LIST, response.data)
      }, async () => {
        await context.commit(SET_USER_LIST, null)
      })
    },
    [FETCH_CREDENTIAL_LIST]: async (context) => {
      return cachedActionWrapper(context, CACHE_USER_CREDENTIALS, async () => {
        const response = await context.rootGetters.restApi.get('auth/credential/')
        await context.commit(SET_CREDENTIALS, response.data)
      }, async () => {
        await context.commit(CLEAR_CREDENTIALS)
      })
    },
  }
}
