<template>
  <v-dialog :value="value" width="400"  v-bind="$attrs" @input="v => $emit('input', v)">
    <v-card>
      <v-container>
        <v-toolbar
            flat
          >
            <v-toolbar-title v-if="isStoppedCash">Kollekte abgeschlossen</v-toolbar-title>
            <v-toolbar-title v-else>Kollekte abschließen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="!hideActions && !hideCancel">
              <v-btn
              icon
              @click="$emit('close')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>

        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <div>
              <v-card-subtitle class="pb-0 text-caption">
                Name der Kollekte
              </v-card-subtitle>
              <v-card-text class="text-subtitle-1 text-primary pb-0">
                <div>{{ disbursementOrder.name }}</div>
              </v-card-text>
            </div>
            <div>
              <v-card-subtitle class="pb-0 text-caption">
                Organisation
              </v-card-subtitle>
              <v-card-text class="text-subtitle-1 text-primary pb-0">
                <div>{{ disbursementOrder.organization_name }}</div>
              </v-card-text>
            </div>
          </v-col>
          <v-col cols="12">
            <v-list dense>
              <v-list-item class="text-subtitle-2" v-if="disbursementOrder.disbursement.total_app_amount">
                <p>
                  Anteil Zahlungen über Apps:
                </p>
                <p class="ml-auto" >
                  {{ disbursementOrder.disbursement.total_app_amount | amount }} {{ currency_symbol }}
                </p>
              </v-list-item>
              <v-list-item class="text-subtitle-2" v-if="disbursementOrder.disbursement.total_device_amount">
                <p>
                  Anteil Zahlungen über Geräte:
                </p>
                <p class="ml-auto">
                  {{ disbursementOrder.disbursement.total_device_amount | amount }} {{ currency_symbol }}
                </p>
              </v-list-item>
              <v-list-item class="text-subtitle-2" v-if="disbursementOrder.disbursement.total_cash_amount">
                <p>
                  Anteil Barzahlungen:
                </p>
                <p class="ml-auto">
                  {{ disbursementOrder.disbursement.total_cash_amount | amount }} {{ currency_symbol }}

                </p>
                <hr />
              </v-list-item>
              <v-divider ></v-divider>
              <v-list-item class="text-subtitle-2 font-weight-bold mt-2" v-if="disbursementOrder.disbursement.total_cash_amount">
                <p>
                  Spendeneinnahmen:
                </p>
                <p class="ml-auto">
                  {{ totalDonations | amount }} {{ currency_symbol }}
                </p>
                <hr />
              </v-list-item>

            </v-list>
          </v-col>
          <v-col cols="12">
            <v-card-text v-if="isStoppedCash">
              Das Dokument mit der Kollektenmeldung / -weiterleitung erhalten Sie per Mail an die hinterlegte Mailadresse.
            </v-card-text>
            <v-card-text v-else>
              Hiermit bestätige ich, dass die oben stehenden Angaben korrekt und vollständig sind. Bitte beachten Sie, dass dieser Vorgang nicht rückgängig gemacht werden kann.
            </v-card-text>
          </v-col>
        </v-row>
        <v-card-actions v-if="!hideActions">
          <v-spacer></v-spacer>
          <v-btn
          v-if="!hideCancel"
                color="darken-1"
                text
                @click="$emit('close')"
              >
              Abbrechen
              </v-btn>
              <v-btn color="primary" text @click="askToConfirm">Kollekte abschließen</v-btn>
        </v-card-actions>
      </v-container>
      <v-dialog v-model="confirmStopCashDialog" width="400">
        <v-card>
          <v-container>
            <v-toolbar
            flat
          >

            <v-toolbar-title>Kollekte abschließen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
              icon
              @click="confirmStopCashDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>

          </v-toolbar>
            <v-row>
              <v-col cols="12">
                <v-card-text v-if="totalDonations === 0">
                  Möchten Sie die Sammlung {{ disbursementOrder.name }} wirklich abschließen? Es sind bisher keine Spenden eingegangen. Wenn Sie die Sammlung verschoben haben, wählen Sie stattdessen "Kollekte abwählen".
                </v-card-text>
                <v-card-text v-else-if="disbursementOrder.disbursement.total_cash_amount === 0">
                  Für die Sammlung {{ disbursementOrder.name }} wurden noch keine Bareinnahmen verbucht. Trotzdem abschließen? Dieser Vorgang kann nicht rückgängig gemacht werden.
                </v-card-text>
                <v-card-text v-else>
                  Möchten Sie die Sammlung {{ disbursementOrder.name }} wirklich abschließen? Dann ist keine Bargeldeintragung mehr möglich und dieser Vorgang kann nicht rückgängig gemacht werden.
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
                  <v-btn
                color="darken-1"
                text
                @click="confirmStopCashDialog = false"
              >
              Abbrechen
              </v-btn>

              <v-btn color="primary" text @click="confirmStopCashPayment">Kollekte abschließen</v-btn>
            </v-card-actions>
          </v-container>
      </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>

import {
  STOP_CASH_PAYMENT,
  FETCH_DISBURSEMENT_ORDER_STATISTIC, FETCH_DISBURSEMENT_ORDERS_FOR_ORGANIZATIONS,
} from '@/store/action-types'
import { SET_ERROR_MESSAGE, SET_SNACKBAR_MESSAGE } from '@/modules/common/store/mutation-types'
import { mapGetters } from "vuex"
import { LEVEL_OPTIONS } from "@/lib/level-options"
export default {
  name: 'StopCashDialog',
  emits: ['close'],
  props: {
    value: { type: Boolean, required: true, default: false },
    hideActions: { type: Boolean, required: false, default: false },
    hideCancel: { type: Boolean, required: false, default: false },
    isAnonymous: { type: Boolean, required: false, default: false },
    token: {
      type: String,
      required: false,
      default: null,
    },
    disbursementOrder: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data () {
    return {
      confirmStopCashDialog: false,
      isStoppedCash: false
    }
  },
  computed: {
    ...mapGetters("organization", ["currentOrganization"]),
    totalDonations () {
      const disbursement = this.disbursementOrder?.disbursement
      if (!disbursement) return 0

      const cash = parseFloat(disbursement?.total_cash_amount) || 0
      const device = parseFloat(disbursement?.total_device_amount) || 0
      const app = parseFloat(disbursement?.total_app_amount) || 0
      const total = cash + device + app
      if (disbursement.total_amount) {
        return disbursement.total_amount
      }
      return total
    },
    currency_symbol () {
      return this.disbursementOrder.currency_symbol || '€'
    },
  },
  methods: {
    askToConfirm () {
      this.confirmStopCashDialog = true
    },
    async confirmStopCashPayment () {
      try {
        if (this.token) {
          const response = await this.$store.getters.restApi.patch(`disbursementorder/secure_link/${this.token}/`)
          if (response.status === 200) {
            this.isStoppedCash = true
            this.confirmStopCashDialog = false
            this.$store.commit(SET_SNACKBAR_MESSAGE, { message: this.$t('messages.success.stop_cash_payment', { collection: this.disbursementOrder.name }) }, { root: true })
          }
        } else {
          await this.$store.dispatch(`disbursement/${STOP_CASH_PAYMENT}`, { disbursementOrderId: this.disbursementOrder.disbursement.id, collectionName: this.disbursementOrder.name })

          if ([LEVEL_OPTIONS.REGION, LEVEL_OPTIONS.DISTRICT].includes(this.currentOrganization.level)) {
            await this.$store.dispatch('disbursement/' + FETCH_DISBURSEMENT_ORDER_STATISTIC, { organizationId: [this.currentOrganization.id] })
          } else {
            await this.$store.dispatch('disbursement/' + FETCH_DISBURSEMENT_ORDERS_FOR_ORGANIZATIONS, { organizationIds: [this.currentOrganization.id] })
          }
          this.$emit('close')
        }
      } catch (error) {
        this.$store.commit(SET_ERROR_MESSAGE, { message: this.$t('messages.error.send') }, { root: true })
      }
    }
  },
  mounted () {
    this.isStoppedCash = this.disbursementOrder.disbursement.stop_cash_payments
  },
}
</script>
