<template>
  <section>
    <stop-cash-dialog
      v-if="disbursementOrder.disbursement"
      :value="true"
      :disbursementOrder="disbursementOrder"
      :token="token"
      hide-cancel
      persistent
    />
    <base-dialog
      :is-open="!!errorMessage"
      persistent
      :close-on-click-outside="false"
      :hide-closing-options="true"
      is-plain
    >
      <template #dialog-title>
        <div/>
      </template>
      <template #dialog-text>
        {{ errorMessage }}
      </template>
      <template #dialog-actions>

      </template>
    </base-dialog>
  </section>
</template>
<script>

import StopCashDialog from '@/components/evaluation/StopCashDialog.vue'
import BaseDialog from "@/components/UI/BaseDialog.vue"
export default {
  components: {
    BaseDialog,
    StopCashDialog
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      organizationName: null,
      collectionName: null,
      successMessage: null,
      disbursementOrder: {},
      errorMessage: null
    }
  },

  async mounted () {
    try {
      const res = await this.$store.getters.restApi.get(`disbursementorder/secure_link/${this.token}/`)
      this.disbursementOrder = {
        name: res.data.collection_name,
        organization_name: res.data.organization_name,
        currency_symbol: res.data.currency_symbol,
        disbursement: {
          total_payments: res.data.total_payments,
          total_app_amount: res.data.total_online_amount,
          total_device_amount: res.data.total_device_amount,
          total_cash_amount: res.data.total_cash_amount,
          stop_cash_payments: res.data.stop_cash_payments,
          total_amount: res.data.total_amount,
        }
      }
    } catch (e) {
      this.errorMessage = e.response.data?.message ?? 'Something went wrong'
    }
  },
}
</script>
