<template>
  <v-dialog
    :value="isOpen"
    :max-width="maxWidth"
    :persistent="persistent"
    @click:outside="closeOnClickOutside"
  >
    <v-card>
      <v-card-title class="d-flex flex-row justify-space-between">
        <div class="flex-1 word-break-normal">
          <slot name="dialog-title">Title</slot>
        </div>
        <v-btn
          v-if="!hideClosingOptions"
          elevation="0"
          fab
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider
        v-if="!isPlain"
        class="mb-4"
      />
      <slot name="dialog-content">
        <v-card-text>
          <slot name="dialog-text">Sample Text</slot>
        </v-card-text>
        <v-divider v-if="!isPlain"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="dialog-actions">
            <v-btn
              v-if="!hideClosingOptions"
              text
              @click="close"
            >
              Schliessen
            </v-btn>
          </slot>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: 800
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnClickOutside: {
      type: Boolean,
      required: false,
      default: true
    },
    hideClosingOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    isPlain: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="stylus" scoped>
.flex-1
  flex: 1

.word-break-normal
  word-break: normal
</style>
