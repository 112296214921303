<template>
  <v-col
    cols="12"
  >
    <div class="d-flex mb-2 justify-end align-center" v-if="filteredItemsByYear.length">
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="primary"
            v-bind="attrs"
            v-on="on"
            :prepend-icon="mdiMenuDown"
          >
            {{ currentYear }}
            <v-icon right>{{ mdiMenuDown }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in years"
            :key="index"
            @click="setYear(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
    </v-menu>
    </div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche nach Sammlungsname, Datum, Liturgischer Tag oder Summe"
          hide-details
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        id="disbursement-orders-parent-table"
        :headers="headers"
        :items="sortedItems"
        :search="search"
        :custom-filter="customSearch"
        :sort-by="['end']"
        :sort-desc="[true]"
        item-key="plan"
        :single-expand="false"
        :expanded.sync="expanded"
        show-expand
        dense
      >
        <template v-slot:expanded-item="{item, headers}">
          <td :colspan="headers.length">
            <v-data-table
              :item-class="(item) => item.state === DISBURSEMENT_STATE.CANCELLED ? 'cancelled' : ''"
              :items="item.items"
              :items-per-page="-1"
              :headers="childTableHeaders"
              class="disbursement-orders-parent-child-table mt-3 mb-3 mr-5"
            >
              <template #item.current_organization_name="{ item }">
                <router-link
                    :to="{ name: 'evaluation.org.disbursementOrders', params: { id: allOrganizations[item.organization].id } }"
                >
                  {{ item.current_organization_name }}
                </router-link>
              </template>
              <template v-slot:item.state="{ item }">
                <span>
                  <v-tooltip
                    v-if="item.state === DISBURSEMENT_STATE.TRANSFERRED || item.total_transferred > 0"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="green darken-2"
                      >
                        {{ mdiCheckCircle }}
                      </v-icon>
                    </template>
                    <span>erledigt</span>
                  </v-tooltip>

                  <v-tooltip
                    v-else-if="item.state === DISBURSEMENT_STATE.IN_PROGRESS || item.total_inprogress > 0"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="orange"
                      >
                        {{ mdiAlert }}
                      </v-icon>
                    </template>
                    <span>zu bearbeiten </span>
                  </v-tooltip>

                  <v-tooltip
                    v-else
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="gray"
                      >
                        {{ mdiMinusCircle }}
                      </v-icon>
                    </template>
                    <span>abgewählt</span>
                  </v-tooltip>

                </span>
          </template>
          <template v-slot:item.total_amount="{ item }">
            <span>{{ (item.total_amount || (Number(item.total_cash_amount) + Number(item.total_device_amount))) || 0 | amount }} € </span>
          </template>
          <template v-slot:item.total_cash_amount="{ item }">
            <span>{{ item.total_cash_amount || 0 | amount }} € </span>
          </template>
          <template v-slot:item.total_online_amount="{ item }">
            <span>{{ item.total_online_amount|| 0 | amount }} € </span>
          </template>
          <template v-slot:item.total_device_amount="{ item }">
            <span>{{ item.total_device_amount || 0 | amount }} € </span>
          </template>
          <template v-slot:item.download_pdf_action="{ item }">
            <div
              class="d-flex"
              v-if="item.state != DISBURSEMENT_STATE.CANCELLED"
            >
              <v-btn
                v-if="item.documents.length > 0 "
                @click="$emit('downloadPdf', {url:item.documents[0], date: isoToHuman(item.stop_cash_payments, 'YYYY-MM-DD') })"
                class="text-caption"
                small
                color="primary "
              >
                <v-icon class="mr-2">mdi-file-download</v-icon>
                PDF
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </td>
    </template>
      <template v-slot:item.name="{ item }">
        <v-tooltip bottom v-if="item.name">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.name.length > 22 ? item.name.substring(0, 45) + '...' : item.name }}
            </span>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.type="{ item }">
        <span>{{ $t(`dashboard.collectionType.${item.type}`) }}</span>
      </template>
      <template v-slot:item.start="{ item }">
        <span>{{ item.start | isoToHuman('L') }}</span>
      </template>
      <template v-slot:item.end="{ item }">
        <span>{{ item.end | isoToHuman('L') }}</span>
      </template>
      <template v-slot:item.day="{ item }">
        <span>{{ item.day || '- -' }}</span>
      </template>
      <template v-slot:item.total_amount="{ item }">
        <strong>{{ item.total_amount || 0 | amount }} €</strong>
      </template>
      <template v-slot:item.total_cash_amount="{ item }">
        <strong>{{ item.total_cash_amount || 0 | amount }} €</strong>
      </template>
      <template v-slot:item.total_online_amount="{ item }">
        <strong>{{ item.total_online_amount || 0 | amount }} €</strong>
      </template>
      <template v-slot:item.total_device_amount="{ item }">
        <strong>{{ item.total_device_amount || 0 | amount }} €</strong>
      </template>
      <template v-slot:item.total_inprogress="{ item }">
        <div class="d-flex align-center">
          <v-tooltip  bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="orange"> {{mdiAlert}} </v-icon>
            </template>
            <span>zu bearbeiten </span>
          </v-tooltip>
          <span>{{ item.total_inprogress }} / {{ item.number_of_children }}</span>
        </div>
      </template>
      <template v-slot:item.total_canceled="{ item }">
        <div class="d-flex align-center">
          <v-tooltip  bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="gray"> {{mdiMinusCircle}} </v-icon>
            </template>
            <span>abgewählt </span>
          </v-tooltip>
          <span>{{ item.total_canceled }} / {{ item.number_of_children }}</span>
        </div>
      </template>
      <template v-slot:item.total_transferred="{ item }">
        <div class="d-flex align-center">
          <v-tooltip   bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="green darken-2"> {{mdiCheckCircle}} </v-icon>
            </template>
            <span>erledigt </span>
          </v-tooltip>
          <span>{{ item.total_transferred }} / {{ item.number_of_children }}</span>
        </div>
      </template>

      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import {
  monthMappingDe,
  monthMappingEn
} from "@/lib/search-by-month"
import {
  mdiEmailOpenOutline,
  mdiEmailOutline,
  mdiFileExcel,
  mdiHelpBox,
  mdiProgressClock,
  mdiArchiveOutline,
  mdiTextBoxMultipleOutline,
  mdiMenuDown,
  mdiCheckCircle,
  mdiAlert,
  mdiTableRemove,
  mdiMinusCircle
} from "@mdi/js"
import {
  mapGetters,
  mapState
} from "vuex"

import { CANCEL_DISBURSEMENT_ORDER } from "@/store/action-types"
import DISBURSEMENT_STATE from "@/lib/disbursement-state"
import { isoToHuman } from "@/filters/datetime"

export default {
  name: "EvalDisbursementParentOrganizationTable",
  emits: ["downloadPdf"],

  props: {
    headers: {
      type: Array,
      required: true
    },
    items: { type: Array },
  },
  data () {
    return {
      isoToHuman,
      DISBURSEMENT_STATE,
      mdiEmailOutline,
      mdiEmailOpenOutline,
      mdiFileExcel,
      mdiHelpBox,
      mdiProgressClock,
      mdiArchiveOutline,
      mdiTextBoxMultipleOutline,
      mdiMenuDown,
      mdiCheckCircle,
      mdiAlert,
      mdiTableRemove,
      mdiMinusCircle,
      selected: [],
      expanded: [],
      search: "",
      currentYear: new Date().getFullYear(),
      stopCashDialog: false,
      addCashCollectionDialog: false,
      selectedDisbursementOrder: null,
    }
  },
  methods: {
    customSearch (value, search, item) {
      search = search.toLowerCase()
      if (Object.keys(monthMappingEn).includes((search))) {
        search = "-" + monthMappingEn[search] + "-"
      }
      if (Object.keys(monthMappingDe).includes(search)) {
        search = "-" + monthMappingDe[search] + "-"
      }
      const searchableColumns = ['total_amount', 'total_cash_amount', 'total_device_amount', 'name', 'end', 'start', 'day']
      return Object.entries(item).some(([key, value]) => {
        if (value && typeof value === 'object') {
          return Object.entries(value).some(([nestedKey, nestedValue]) => {
            if (searchableColumns.includes(nestedKey)) {
              return nestedValue && nestedValue.toString().toLowerCase().includes(search)
            }
            return false
          })
        } else {
          if (searchableColumns.includes(key)) {
            return value && value.toString().toLowerCase().includes(search)
          }
        }

        return false
      })
    },
    setYear (year) {
      this.currentYear = year
    },
    cancelDisbursementOrder (id) {
      this.$store.dispatch(`disbursement/${CANCEL_DISBURSEMENT_ORDER}`, id)
    },
  },
  computed: {
    ...mapGetters("organization", ["currentOrganization", "allOrganizations"]),
    ...mapState(['loading']),
    years () {
      return ['Alle', ...new Set(this.items.map(item => item.end.split('-')[0]))]
    },
    filteredItemsByYear () {
      if (this.currentYear === 'Alle') {
        return this.items
      }
      return this.items.filter(item => {
        return item.end.includes(this.currentYear)
      })
    },
    sortedItems () {
      return this.filteredItemsByYear.map(item => {
        item.items = item.items.sort((itemA, itemB) => {
          if (itemA.total_transferred > itemB.total_transferred) {
            return -1
          }
          if (itemA.total_transferred < itemB.total_transferred) {
            return 1
          }

          if (itemA.total_inprogress > itemB.total_inprogress) {
            return -1
          }
          if (itemA.total_inprogress < itemB.total_inprogress) {
            return 1
          }

          if (itemA.total_canceled > itemB.total_canceled) {
            return -1
          }

          if (itemA.total_canceled < itemB.total_canceled) {
            return 1
          }
          if (itemA.current_organization_name > itemB.current_organization_name) {
            return 1
          }
          if (itemA.current_organization_name < itemB.current_organization_name) {
            return -1
          }

          return 0
        })

        return item
      })
    },
    effectiveOrganizationSettings () {
      return this.$store.getters["settings/effectiveSettingsForUrl"](this.currentOrganization.url)
    },
    childTableHeaders () {
      return [
        { text: "", value: "current_organization_name" },
        { text: "Spendensumme", value: "total_amount" },
        { text: "Baranteil", value: "total_cash_amount" },
        { text: "Online-Anteil", value: "total_online_amount" },
        { text: "Geräte-Anteil", value: "total_device_amount" },
        { text: "", value: "state" },
        // { text: "", value: "download_pdf_action" },
      ]
    },
  },
}
</script>

<style lang="scss" >

#disbursement-orders-parent-table{
  .cancelled {
    color: #a7a7a7 !important;
    text-decoration: line-through;

  }
}

.disbursement-orders-parent-child-table {
  max-width: 850px;
  margin-left: 20px;
}
</style>
