<template>
  <v-container id="eval-disbursement-orders">
    <v-row>
      <v-col cols="12">
        <h2 class="mt-6">Übersicht über alle Kollektenmeldungen</h2>
        <p>Hier finden Sie alle beendeten Weiterleitungskollekten. Bitte schließen Sie diese zeitnah ab.</p>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="">
       <eval-disbursement-parent-organization-table
          v-if="tableList.length > 0"
          :headers="headers"
          :items="tableList"
          @exportDataMethod="exportList"
          @downloadPdf="download"
       />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
import { mapGetters, mapState } from "vuex"
import { downloadBlob } from '@/lib/file-tools'
import EvalDisbursementParentOrganizationTable from "@/components/evaluation/EvalDisbursementParentOrganizationTable.vue"

import { shortenParish } from "@/lib/regex-tools"
export default {
  name: "EvalDisbursementParentList",
  components: { EvalDisbursementParentOrganizationTable },
  data () {
    return {
      donationReceiptRequests: [],
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState('location', ['locations']),
    ...mapGetters("organization", ["currentOrganization", "allOrganizations"]),
    ...mapState("organization", ["organizations"]),
    ...mapGetters("collectionPlan", ["currentCollectionPlans", "collectionPlansLookup", 'allCollectionPlansByUrl']),
    ...mapGetters("disbursement", ["disbursementOrders"]),
    ...mapGetters("date", ["globalDatesByUrl"]),
    tableList () {
      if (!this.currentOrganization || !this.currentOrganization.url) {
        return []
      }
      if (!this.disbursementOrders || !this.disbursementOrders.length) {
        return []
      }
      const result = {}

      this.disbursementOrders.forEach(disbursement => {
        if (!result[disbursement.plan]) {
          result[disbursement.plan] = {
            ...disbursement,
            organization: this.currentOrganization.url,
            items: []
          }
        }
        const items = result[disbursement.plan].items

        if (disbursement.organization === this.currentOrganization.url) {
          result[disbursement.plan] = {
            ...disbursement,
            items
          }
        } else {
          result[disbursement.plan].items.push({
            ...disbursement,
            current_organization_name: shortenParish(this.allOrganizations[disbursement.organization].name)
          })
        }
      })

      return Object.values(result)
    },
    headers () {
      return [
        { text: "Sammlungsname", value: "plan_name" },
        { text: "Typ", value: "collection_display_type", filterable: false },
        // { text: "Geltungsbereich", value: "location", filterable: false },
        { text: "Liturgischer Tag", value: "day_name" },
        { text: "Startdatum", value: "start" },
        { text: "Enddatum", value: "end" },
        // { text: "Spendensumme", value: "total_amount", align: 'end' },
        { text: "Baranteil", value: "total_cash_amount", align: 'end' },
        { text: "Online-Anteil", value: "total_online_amount", align: 'end', width: '100' },
        { text: "Geräte-Anteil", value: "total_device_amount", align: 'end' },
        { text: "", value: "total_canceled", width: '100' },
        { text: "", value: "total_inprogress", width: '100' },
        { text: "", value: "total_transferred", width: '100' },
      ]
    },
  },
  methods: {
    async download ({ url, date }) {
      const response = await this.$store.getters.restApi.get(`${url}/download/`, { responseType: 'blob' })
      downloadBlob(response.data, `${date}_Auszahlungsanordnung_ID.pdf`, response.headers['content-type'])
    },
    async exportList () {
      const response = await this.$store.getters.restApi.get(`pdata/donation_receipt/xlsx/`, {
        responseType: 'blob',
        params: {
          organization: this.currentOrganization.id
        }
      })
      const now = moment().format("YYYY-MM-DD HH-MM-SS")
      downloadBlob(response.data, `${now}_${this.currentOrganization.name}_Angefragte Zuwendungsbestätigungen.xlsx`, response.headers['content-type'])
    },
  },
}
</script>
<style lang="stylus" scoped>

.v-data-table ::v-deep th
  font-size: 0.9em !important/*
.v-data-table ::v-deep td
  font-size: 0.9em !important*/
.archived
  color: #BDBDBD
</style>
