import {
  INC_LOADING,
  DEC_LOADING,
  SET_ERROR_MESSAGE,
  SET_SNACKBAR_MESSAGE
} from '@/modules/common/store/mutation-types'
import {
  FETCH_DISBURSEMENT_ORDERS,
  STOP_CASH_PAYMENT,
  CANCEL_DISBURSEMENT_ORDER,
  FETCH_DISBURSEMENT_ORDERS_FOR_ORGANIZATIONS,
  FETCH_DISBURSEMENT_ORDER_STATISTIC
} from './action-types'

import {
  SET_DISBURSEMENT_ORDERS,
  UPDATE_DISBURSEMENT_ORDERS
} from './mutation-types'

import i18n from '@/i18n'

export default {
  namespaced: true,
  state: function () {
    return {
      disbursementOrders: [],
    }
  },
  getters: {
    disbursementOrders: state => state.disbursementOrders
  },
  mutations: {
    [SET_DISBURSEMENT_ORDERS]: (state, data) => {
      state.disbursementOrders = data
    },
    [UPDATE_DISBURSEMENT_ORDERS]: (state, disbursementOrder) => {
      const index = state.disbursementOrders.findIndex(item => item.id === disbursementOrder.id)
      if (index !== -1) {
        state.disbursementOrders.splice(index, 1, disbursementOrder)
      }
    }
  },
  actions: {
    [FETCH_DISBURSEMENT_ORDERS]: async ({
      commit,
      rootGetters
    }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const { data, error } = await rootGetters.restApi.get('disbursementorder')
        if (data) {
          commit(SET_DISBURSEMENT_ORDERS, data)
        } else {
          commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        }
      } catch (error) {
        commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [FETCH_DISBURSEMENT_ORDER_STATISTIC]: async (
      {
        commit,
        rootGetters
      },
      { organizationId }
    ) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const { data, error } = await rootGetters.restApi.get(`organization/${organizationId}/disbursement_order_statistic/`)
        if (data) {
          commit(SET_DISBURSEMENT_ORDERS, data)
        } else {
          commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        }
      } catch (error) {
        commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [FETCH_DISBURSEMENT_ORDERS_FOR_ORGANIZATIONS]: async (
      {
        commit,
        rootGetters
      },
      { organizationIds }
    ) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const { data, error } = await rootGetters.restApi.get('disbursementorder/?organization=' + organizationIds.join(','))
        if (data) {
          commit(SET_DISBURSEMENT_ORDERS, data)
        } else {
          commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        }
      } catch (error) {
        commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [STOP_CASH_PAYMENT]: async ({
      commit,
      rootGetters
    }, { disbursementOrderId, collectionName }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const { data, error } = await rootGetters.restApi.patch(`disbursementorder/${disbursementOrderId}/stop_cash_payments/`)
        if (data) {
          commit(UPDATE_DISBURSEMENT_ORDERS, data)
          commit(SET_SNACKBAR_MESSAGE, { message: i18n.t('messages.success.stop_cash_payment', { collection: collectionName }) }, { root: true })
        } else {
          commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        }
      } catch (error) {
        const errorMessages = error.response.data.join('; ')
        commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${errorMessages}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [CANCEL_DISBURSEMENT_ORDER]: async ({
      commit,
      rootGetters
    }, disbursementOrderId) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const { data, error } = await rootGetters.restApi.patch(`disbursementorder/${disbursementOrderId}/cancel/`)
        if (data) {
          commit(UPDATE_DISBURSEMENT_ORDERS, data)
        } else {
          commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        }
      } catch (error) {
        commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    }
  }
}
